import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo";
import AboutImage from "../components/about_image";
import ServicesImage from "../components/service_image";
import HealthImage from "../components/health_image";
import ContactImage from "../components/contact_image";

const ContactUs = () => (
    <Layout>
        <SEO title="Contact Us" description="Contact us for a quote"/>
        <div className={'max-w-screen-lg mx-auto'}>
            <Logo />
        </div>
        <div className={'bg-white p-4 max-w-screen-lg mx-auto'}>
            <h1 className={'text-2xl text-indigo-700'}>Contact Us</h1>
            <div className={'grid grid-cols-2 gap-2'}>
                <div>
                    <table width="621" border="0" cellSpacing="0" cellPadding="4">
                        <tbody>
                        <tr valign="top">
                            <td width="28%"><strong>Address:</strong></td>
                            <td width="72%">
                                <p>Silo Cleaning Services UK LLP <br />Unit 3, Oakney Wood Court,<br />Selby Business Park</p>
                                <p>Selby<br /> North Yorkshire <br /> YO8 8FN</p>
                            </td>
                        </tr>
                        <tr valign="top">
                            <td><strong>Tel / Fax:</strong></td>
                            <td>01757 290808</td>
                        </tr>
                        <tr valign="top">
                            <td><strong>Mobile:</strong></td>
                            <td>07778 645828</td>
                        </tr>
                        <tr valign="top">
                            <td><strong>Email:</strong></td>
                            <td><a href="mailto:scsselby@btinternet.com">Silo Cleaning Services Email</a></td>
                        </tr>
                        <tr valign="top">
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr valign="top">
                            <td><span>VAT No.</span></td>
                            <td>647 3369 13</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="contact" />
                        <p className={'flex flex-col mt-2'}>
                            <label htmlFor="name" className={'hidden'}>Name</label>
                            <input type="text" name="name" placeholder="Name" className={'w-100 mt-2 py-2 px-2 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none'}/>
                        </p>
                        <p className={'flex flex-col mt-2'}>
                            <label htmlFor="phone" className={'hidden'}>Telephone</label>
                            <input type="tel" name="phone" placeholder="Telephone" className={'w-100 mt-2 py-2 px-2 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none'}/>
                        </p>
                        <p className={'flex flex-col mt-2'}>
                            <label htmlFor="email" className={'hidden'}>Email</label>
                            <input type="email" name="email" placeholder="Email" className={'w-100 mt-2 py-2 px-2 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none'}/>
                        </p>
                        <p className={'flex flex-col mt-2'}>
                            <label htmlFor="company" className={'hidden'}>Company</label>
                            <input type="text" name="company" placeholder="Company" className={'w-100 mt-2 py-2 px-2 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none'}/>
                        </p>
                        <p className={'flex flex-col mt-2'}>
                            <label htmlFor="message" className={'hidden'}>Message</label>
                            <textarea trows="4" cols="50" name="message" placeholder="Message" className={'w-100 mt-2 py-2 px-2 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none'}/>
                        </p>
                        <p>
                            <button type="submit" className={'px-10 mt-2 rounded-md text-white bg-gray-800 p-2'}>Send</button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
        <div className={'max-w-screen-lg mx-auto'}>
            <div className={'flex flex-wrap text-white mt-4'}>
                <div className={'flex-1 pb-2 my-2 mr-2 bg-yellow-500'}>
                    <AboutImage />
                    <div className={'px-4'}>
                        <h2 className={'text-2xl font-normal mb-2'}>about us</h2>
                    </div>
                    <div className={'text-center'}>
                        <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/about-us">More Information</Link>
                    </div>
                </div>
                <div className={'flex-1 pb-2 m-2 bg-orange-500'}>
                    <ServicesImage />
                    <div className={'px-4'}>
                        <h2 className={'text-2xl font-normal mb-2'}>our services</h2>
                    </div>
                    <div className={'text-center'}>
                        <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/our-services">More Information</Link>
                    </div>
                </div>
                <div className={'flex-1 pb-2 m-2 bg-teal-700'}>
                    <HealthImage />
                    <div className={'px-4'}>
                        <h2 className={'text-2xl font-normal mb-2'}>health & safety</h2>
                    </div>
                    <div className={'text-center'}>
                        <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/health-and-safety">More Information</Link>
                    </div>
                </div>
                <div className={'flex-1 pb-2 m-2 ml-2 bg-indigo-700'}>
                    <ContactImage />
                    <div className={'px-4'}>
                        <h2 className={'text-2xl font-normal mb-2'}>contact us</h2>
                    </div>
                    <div className={'text-center'}>
                        <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/contact-us">More Information</Link>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default ContactUs
